import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import logoUrl from '../../cashmax-title-and-loan.png';
import licenseUrl from '../../license.jpg';

var link = '#';

class Logo extends Component {
  render() {
    return (
      <footer className="apply-now-footer">
        <Container>
          <Row>
            <Col>
              <img
                src={logoUrl}
                alt="CashMax"
                className="img-responsive footer-logo-img"
              />
              <a href={link} className="yellow-button">
                APPLY TODAY
              </a>
              <a href={link} className="yellow-button">
                FIND A LOCATION
              </a>
              <div className="disclosures">
                <br />
                <br />
                <strong>Disclosures &amp; Fees</strong>
                <br />
                <a href={link}>Disclosure Information</a>
                <br />
                <a href={link}>Fee Information</a>
              </div>
            </Col>
            <Col>
              <div className="disclosures">
                <strong>Recent Blog Posts</strong>
                <br />
                <br />
                <a href={link}>
                  Ways to Financial Relief - Why wait until April to
                  file your taxes?
                </a>
                <br />
                <a href={link}>
                  Ways to Financial Relief - Setting Financial
                  Resolutions
                </a>
                <br />
                <a href={link}>
                  Ways to Financial Relief - 2024 Wrap Up
                </a>
                <br />
                <a href={link}>
                  Ways to Financial Relief - Don't Fall for seasonal
                  Financial Surprises
                </a>
                <br />
                <a href={link}>
                  Ways to Financial Relief - September is
                  Self-Improvement Month
                </a>
                <br />
              </div>
            </Col>
            <Col>
              <div className="disclosures">
                <strong>Recent Blog Posts</strong>
                <br />
                <br />
                <a href={link}>Careers</a>
                <br />
                <a href={link}>Customer Testimonials</a>
                <br />
                <a href={link}>Frequently Asked Questions</a>
                <br />
                <a href={link}>Apply Online</a>
                <br />
              </div>
            </Col>
            <Col>
              <div className="text-center">
                <img
                  src={licenseUrl}
                  alt="License"
                  className="img-responsive footer-license-img"
                />
                <br />
                <br /> Click The Image To Enlarge
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <br />
              <div className="disclosures">
                <div className="text-center">
                  &copy; CashMax Title &amp; Loan | All Rights
                  Reserved | Privacy | Sitemap
                </div>
                <p></p>
                <p className="text-center">
                  Loans subject to lender approval. Not all customers
                  will qualify for loan or qualify for maximum loan
                  amount. Loan terms and availability may vary by
                  location. <br />
                  Loans are provided by an unaffiliated third-party
                  lender.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}
export default Logo;
