import React, { Component } from 'react';

import logoUrl from '../../cashmax-title-and-loan.png';

class Logo extends Component {
  render() {
    return (
      <header className="apply-now-header">
        <div>
          <div className="logo">
            <div align="left">
              <img
                src={logoUrl}
                alt="CashMax"
                className="img-responsive logo-img"
              />
            </div>
          </div>
          <div className="cashmax-menu">
            <ul className="cashmax-menu-ul">
              <li>HOME</li>
              <li className="active">APPLY NOW</li>
              <li>LOCATIONS</li>
              <li>LOANS</li>
              <li>RESOURCES</li>
              <li>CAREERS</li>
            </ul>
          </div>
          <div className="clearNone"></div>
        </div>
      </header>
    );
  }
}
export default Logo;
