import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './Main';
import Unsubscribe from './Unsubscribe';
import NotFound from './NotFound';
import Calculator from './Calculator';
import HoldingPage from './holding/HoldingPage';
import InternalApplication from './internal/InternalApplication';

class PageRouter extends Component {
  state = {};
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/apply-now/:id" element={<HoldingPage />} />
          <Route path="/application/:id" element={<Main />} />
          <Route
            path="/welcome/:id"
            element={<InternalApplication />}
          />
          <Route path="/unsubscribe/:id" element={<Unsubscribe />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default PageRouter;
