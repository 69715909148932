import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Logo from '../holding/Logo';
import Footer from '../holding/Footer';
import Banner from './Banner';
import Store from './Store';

export default function Main() {
  const { id } = useParams();
  const [store, setStore] = useState(null);
  const [customerPhone, setCustomerPhone] = useState(null);
  const fetchStoreComplete = useRef(false);

  useEffect(() => {
    if (fetchStoreComplete.current === false) {
      fetch(`https://leadsherpapost.com/cashmax/api/v1/getapp/${id}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.store) {
            setStore(data.store);
            setCustomerPhone(data.phone);
          }
          fetchStoreComplete.current = true;
        });
    }
  }, [id, store]);

  return (
    <div className="apply-now">
      <Logo />
      <div className="main">
        <div className="container">
          {!store ? (
            <div className="waiting-message">
              Sorry, we cannot find your application.
            </div>
          ) : (
            <div>
              <div className="container">
                <Banner
                  store={store}
                  customerPhone={customerPhone}
                ></Banner>
                <Store store={store}></Store>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
