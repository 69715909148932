import React from 'react';

import checkmark from '../../check-lite.png';
import callcenterUrl from '../../cc2.png';

function Banner({ store, customerPhone }) {
  return (
    <div className="card card-primary mb-0">
      <div className="card-body pt-2 pe-2 pb-0 ps-2">
        <div className="pt-2 pt-lg-3 pt-xl-3 ps-2 ps-lg-5 ps-xl-5 pe-2 pe-lg-5 pe-xl-5">
          <h2 className="text-center">
            Thanks for submitting your information.
          </h2>
          <div className="text-center pt-3 pb-3">
            {customerPhone ? (
              <div>
                An experienced representative will be reaching out to
                the number you provided: {customerPhone} <br />
              </div>
            ) : (
              <div></div>
            )}
            {store ? (
              <div>
                Can't wait? Call us at &nbsp;
                <a href={'tel: ' + store.phone}>{store.phone}</a> to
                expedite your service.
              </div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 sm-6">
              <div className="process-title">
                <strong>The process is easy!</strong>
              </div>
              <div className="pt-1 pb-2 row">
                <div className="col-1">
                  <img
                    src={checkmark}
                    alt="check"
                    width="30"
                    height="30"
                  />
                </div>
                <div className="col-11 pt-1">
                  Speak with a customer service representative.
                </div>
              </div>
              <div className="pt-1 pb-2 row">
                <div className="col-1">
                  <img
                    src={checkmark}
                    alt="check"
                    width="30"
                    height="30"
                  />
                </div>
                <div className="col-11 pt-1">
                  Visit one of our stores to complete the process.
                </div>
              </div>
              <div className="pt-1 pb-2 row">
                <div className="col-1">
                  <img
                    src={checkmark}
                    alt="check"
                    width="30"
                    height="30"
                  />
                </div>
                <div className="col-11 pt-1">
                  Get approved for your cash.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="img-girl" align="right">
                <div className="pt-0"></div>
                <img
                  src={callcenterUrl}
                  alt="callcenter"
                  className="img-responsive text-center"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="about-us">
          <div className="text-center pt-2">
            <strong className="about-us-header">About Us:</strong>
          </div>
          <div className="p-2 text-start text-lg-center text-xl-center">
            CashMax is one of Texas's largest title lending companies
            offering loans up to $15,000. Every day, CashMax helps
            people get the cash they need through a title-secured loan
            from your vehicle, motorcycle, ATV, RV, Trailer, boat, and
            so much more. We offer very competitive rates and provide
            high quality customer service. Since we first opened in
            2003, CashMax has expanded to more than 35 locations,
            spanning 28 counties. We take great pride in providing
            customers with the loan they need in the time they need
            it.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
