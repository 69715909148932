import React, { useState } from 'react';

import './Main.css';
import Logo from './Logo';
import Button from 'react-bootstrap/Button';
import { Container, Row, Col, Form, Stack } from 'react-bootstrap';

function AmountDisplay({ amount, currency, locale }) {
  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(amount);

  return <span>{formattedAmount}</span>;
}

export default function Main() {
  const [paycheckAmount, setPaycheckAmount] = useState(1686);
  const [monthlyIncome, setMonthlyIncome] = useState(1686);

  function handleCalculate() {
    setPaycheckAmount(1686);
    setMonthlyIncome(1686);
  }

  return (
    <div className="cashmax">
      <Logo />
      <div className="main-body-calc">
        <div className="container-fluid">
          <Container>
            <Row>
              <Col></Col>
              <Col>
                <br />
                <br />
                <br />
                <Form>
                  <Stack gap={2}>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Paycheck Amount</Form.Label>
                      <Form.Control
                        type="text"
                        value={paycheckAmount}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Pay Frequency</Form.Label>
                      <Form.Select aria-label="">
                        <option value="S">Semi-Monthly</option>
                        <option value="M">Monthly</option>
                        <option value="B">Bi-Weekly</option>
                        <option value="W">Weekly</option>
                      </Form.Select>
                    </Form.Group>
                    <Stack direction="horizontal" gap={3}>
                      <div>
                        <Form.Label>KBB Value</Form.Label>
                        <Form.Control
                          type="text"
                          value={paycheckAmount}
                          onChange={handleCalculate}
                        />
                      </div>
                      <div>
                        <br />
                        <Button variant="primary" type="submit">
                          Calculate
                        </Button>
                      </div>
                    </Stack>
                  </Stack>
                </Form>
              </Col>
              <Col>
                <Stack gap={2} className="calc-right">
                  <h2 className="text-center">Your results</h2>
                  <h3>Monthly Income</h3>
                  <div className="amount">
                    <AmountDisplay
                      amount={monthlyIncome}
                      currency="USD"
                      locale="en-US"
                    />
                  </div>
                  <h3>Avg. monthly interest</h3>
                  <div className="amount">
                    <AmountDisplay
                      amount={20}
                      currency="USD"
                      locale="en-US"
                    />
                  </div>
                  <h3>Total interest</h3>
                  <div className="amount">
                    <AmountDisplay
                      amount={117}
                      currency="USD"
                      locale="en-US"
                    />
                  </div>
                  <h3>Total cost of finance</h3>
                  <div className="amount">
                    <AmountDisplay
                      amount={10117}
                      currency="USD"
                      locale="en-US"
                    />
                  </div>
                </Stack>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
