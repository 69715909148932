import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Logo from './Logo';
import Footer from './Footer';
import callcenterUrl from '../../cc2.png';

export default function Main() {
  const { id } = useParams();
  const [accepted, setAccepted] = useState(null);
  const [rejected, setRejected] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const fetchStoreComplete = useRef(false);

  useEffect(() => {
    if (fetchStoreComplete.current === false) {
      fetch(`https://leadsherpapost.com/cashmax/api/v1/getapp/${id}`)
        .then((response) => response.json())
        .then((data) => {
          // if (data.store) {
          //   setAccepted(true);
          //   setRedirectUrl('https://google.com');
          //   setTimeout(() => {
          //     //window.location.href = 'https://google.com';
          //   }, 5000);
          // } else {
          setRejected(true);
          // }
          fetchStoreComplete.current = true;
        });
    }
  }, [id, accepted, rejected]);

  return (
    <div className="apply-now">
      <Logo />
      <div className="main">
        <div className="container">
          {!accepted && !rejected ? (
            <div className="waiting-message">
              Sorry, you are outside of our service area but we're
              checking with our partners to see if they can provide
              your loan. If so, you will be connected to their website
              to confirm your information and get your money.
              <br /> <br />
              Please remain on this page while we find a partner to
              assist you.
            </div>
          ) : (
            <div></div>
          )}
          {accepted ? (
            <div className="waiting-message">
              Great news! We found a partner that can assist with your
              loan. You will now be redirected to their website to
              continue. <br />
              *If you are not redirected, please click&nbsp;
              <a href={redirectUrl}>here</a>
            </div>
          ) : (
            <div></div>
          )}
          {rejected ? (
            <div className="waiting-message">
              Sorry, based on the information you submitted, we could
              not find any qualified partners to assist. You're
              welcome to try again at a later time.
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="img-girl" align="right">
          <div className="pt-0"></div>
          <img
            src={callcenterUrl}
            alt="callcenter"
            className="img-responsive text-center"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
